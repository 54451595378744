import styled from "@emotion/styled";
import { Box } from "@mui/material";
import variables from "../../scss/style.module.scss";

export const StyledBox = styled(Box)`
  height: 320px;
  width: 281px !important;
  margin-bottom: 20px;
  margin-left: 5px;

  .container {
    position: relative;
    width: 90%;
  }

  .fadeout{
    position: absolute;
    top: -87px;
    right: 50px;    
    color: white;  
    font-family : ${variables.basefont}; 
    font-weight: 400;
    font-size: 1.125rem;
  }
 .tile__sector:before 
 {
   background-color: #d6002a;
   content: "";
   display: inline-block;
   height: 0.125rem;
   margin: 0.625rem 0.625rem 0 0;
   vertical-align: top;
   width: 1.25rem;
  }

 .description{
   font-size: .900rem;
   line-height: 1.63;
   -webkit-animation: fadeOut 8s;
   opacity: 40%;
   text-align: left;
   margin-left: 20px;
   overflow-wrap: anywhere !important;
  }

  .overlay {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${variables.whitecolor};
    width: 281px;
    height: 0px;
    transition: 0.5s ease-in-out;
  }

    .container:hover .overlay {
    height: 98%;
    border-top: 10px solid ${variables.blackcolor};
    
    .tile__sector {
      display: none;
    }
    .fadeout{
      display: none;
    }
    .footer_icon{
      display: inline-block;
      margin-top: 270px;      
    }
    .icon{
      margin-left: -120px;
      float : left;
    }
    .btn{
      font-weight: 700;
      font-size:16px;
      background-color: transparent !important;
      font-family: ${variables.basefont};
      position:absolute;
      display:inline;
      text-align: right;
      margin-left: 50px;
      margin-bottom: -10px;
      padding : 0px 10px; 0px 0px;
    }
     
  }
  
  .text {
    color: ${variables.blackcolor};
    font-size: 15px;
    position: absolute;
    text-align: left;
    margin: 0px 5px 0px 20px;
    font-family: ${variables.basefont};
    font-weight: 400;
    transition: color ease-in-out 200ms;
    line-height: 1.43;
    overflow-wrap: anywhere !important;
  }
`;

export const VASliderStyle = styled.div`

  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    display:inline;
    text-align: center;
  }

.img-gradient {
  position: relative;
}

.img-gradient::after {
  content: '';
  position: absolute;
  left: 0; 
  top: 0;
  display: inline-block;
  width: 281px; height: 320px;
  opacity: 1;
  background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.4) 0.02%, ${variables.blackcolor} 81.11%);
  -webkit-transition: opacity 10ms ease-in 200ms;
  margin-bottom: 0;
}


 .footer_icon{
     display: none;
  
 }

.tooltiptext{
  font-family: ${variables.basefont};
}

  // .related-va-title {
  //   position: absolute;
  //   top: 15px;
  //   left: 10px;
  //   text-transform: uppercase;
  //   font-size: 12px !important;
  //   float: left;
  //   text-align: left;
  //   padding: 10px 0px 0px 20px;
  //   width: 100%;
  //   color: #ffffff;
  // }

  .related-va-sub-title {
    position: absolute;
    top: 55px;
    left: 10px;
    float: left;
    text-align: left;
    padding: 0px 10px 50px 5px;
    width: 100%;
    color:${variables.whitecolor};
    font-weight: 700;
    font-size: 32px;
    font-family: ${variables.basefont};
  }

  .slick-prev:before {
    content: "←";
    color:  ${variables.blackcolor};;
  }

  .slick-next:before {
    content: "→";
    color:  ${variables.blackcolor};;
  }

.slick-dots {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 1rem 0;
	
	list-style-type: none;
	
		li {
			margin: 0 1.25rem;
		}
	
		button {
			display: block;
			width: 3rem;
			height: 0.3125rem;
			padding: 0;
			
			border: none;
			border-radius: 0%;
			background-color: #ccc;
			
			text-indent: -9999px;
		}
	
		li.slick-active button {
			background-color:  ${variables.blackcolor};;
		}
`;

// export const DivStyle = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   padding: 5px;
//   text-transform: none;
//   text-align: left;
//   margin-top: 110px;

//   .flexbox-container {
//     display: flex;
//     flex-direction: row;
//   }
// `;
