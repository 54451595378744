import styled from "@emotion/styled";
import AppBar from "@mui/material/AppBar";
import variables from "../../scss/style.module.scss";
import { Button, Typography } from "@mui/material";

export const StyledAppBar = styled(AppBar)`
  background: ${variables.blackcolor};
  box-shadow: none;
  height: 48px;
  padding: 0px, 15px, 0px, 15px;
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  margin: 10px 30px 20px 0px !important;
  height: 28px;
  background: ${variables.blackcolor};
  color: ${variables.whitecolor};
  font-family: ${variables.basefont};

  &:hover {
    background-color: ${variables.blackcolor};
    color: ${variables.redcolor};
    //border: 2px solid ${variables.redcolor};
  }
`;

// export const StyledViewButton = styled(Button)`
//   text-transform: none;
//   font-weight: 400;
//   color: ${variables.whitecolor};
//   font-family: ${variables.basefont};
//   top: 3%;
//   margin-bottom: -20px;
//   position: static;
//   //font-size: 11px;
//   display: flex;
//   right: 4%;
// `;

export const StyledTypography = styled(Typography)`
font-family: ${variables.basefont};
padding: 0px 0px 11px 15px;
}`;
