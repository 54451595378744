import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import variables from "../../scss/style.module.scss";
import Container from "@mui/material/Container";

export const StyledAppBar = styled(AppBar)`
  background-color: #f8f8f8;
  box-shadow: none;
  height: 75px;
  padding: 16px, 24px, 16px, 24px;
  display: flex !important;
  flex-direction: row;
  gap: 150px;
  .divider {
    margin: 0px 5px 0px 5px !important;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
`;

export const StyleTypoGraphy = styled(Typography)`
  text-transform: none;
  font-weight: 400;
  font-size: 10px;
  font-family: ${variables.basefont1};
  // line-height: 0px;
  // padding-top: 17px;
  color: #525252 !important;
`;

export const StyledLink = styled(Link)`
  text-transform: none;
  font-weight: 400;
  font-size: 10px !important;
  padding-left: 0px;
  font-family: ${variables.basefont1};
  color: #525252 !important;
  text-decoration-color: #525252;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;
