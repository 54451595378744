import styled from "@emotion/styled";
import { Button, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import variables from "../../scss/style.module.scss";
import { FullScreen } from "react-full-screen";

export const DetailsStyle = styled.div`
  .css-19kzrtu {
    padding: 0px !important;
  }
`;

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin: 15px 0px 15px 20px;
  font-weight: 400;
  font-family: ${variables.basefont1};
  font-size: 18px;
  line-height: 24px;

  .breadcrumb-hover {
    &:hover {
      color: ${variables.redcolor};
    }
  }
`;

export const StyledTitleBar = styled.div`
  .list-style {
    list-style-type: none;
    line-height: 50px;
  }

  .details-description {
    border-right: 0.0625rem solid ${variables.lightgreycolor};
    padding: 0px 15px 15px 0px;
  }

  .definition-item {
    background-image: linear-gradient(
      to bottom,
      $color-grey-80 0%,
      $color-grey-80 100%
    );

    &::after {
      content: "";
      display: inline-flex;
      flex-grow: 1;
      order: -1;
    }

    &__label,
    &__text {
    }

    &__label {
      background-color: $color-white;
      box-shadow: 0.5em 0 0 $color-white;
      order: -1;
      font-size: 15px;
      font-family: ${variables.basefont};
      font-weight: 700;

      &::after {
        content: none;
      }
    }

    &__text {
      width: 50%;
      font-size: 16px;
      font-family: ${variables.basefont};
    }

    &--grey {
      &:nth-of-type(odd) {
        background-color: $color-white-smoke;
      }

      .definition-item__label {
        background-color: $color-white-smoke;
        box-shadow: 0.5em 0 0 $color-white-smoke;
      }
    }
  }
`;

export const StyledTabTitle = styled.div`
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px solid ${variables.lightgreycolor};
  margin-bottom: 15px;
`;

export const BorderDescription = styled.div`
  display: inline-flex;
  flex-grow: 1;
  order: -1;
  width: calc(100% / 10);
  border-bottom: 1px solid ${variables.lightgreycolor};
  margin: 0px 5px 5px 5px;
`;

export const DetailsTitle = styled.div`
  display: flex;
  // padding-top: 1px;
  // width: 422px;
  width: auto;
  height: 34px;
  font-weight: 700;
  font-size: 30px;
  line-height: 34.5px;
  font-family: "Akk pro";
  color: #000000;
`;

export const StyledButton = styled(Button)`
  color: ${variables.blackcolor};
  font-weight: bold;
  text-transform: none;
  border-color: ${variables.blackcolor};
  border-radius: 0px 0px 0px 0px;

  &:hover {
    background-color: ${variables.blackcolor};
    color: ${variables.whitecolor};
  }
`;

export const StyledViewButton = styled(Button)`
  text-transform: none;
  font-weight: bold;
  color: ${variables.blackcolor};
  // margin: 10px 0px 20px 0px;
  // font-size: 8px;
  font-family: ${variables.basefont};
  top: 2%;
  z-index: 999;
  margin: 0px !important;
  padding: 0px !important;
  // position: absolute;
  font-size: 0.6rem;
  right: 4%;

  &:hover {
    color: ${variables.redcolor};
    background-color: ${variables.whitecolor};
  }
`;

export const DisabledTextButton = styled(Button)`
  color: ${variables.blackcolor};
  text-transform: none;
`;

export const StyledTab = styled(Tab)`
  margin-top: 15px;
  color: ${variables.blackcolor};
  text-transform: none;
  font-size: 18px;
  font-family: ${variables.basefont};
  font-weight: 700;
`;

export const StyledTypography = styled(Typography)`
  padding-top: 20px;
  font-family: ${variables.basefont};
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1px;
`;

export const StyledStack = styled(Stack)`
  margin-bottom: 20px;
  margin-left: 20px;
`;

export const StyledDiv = styled.div`
  margin-left: 20px;
  position: relative;
`;

export const StyledChip = styled(Chip)`
  color: ${variables.blackcolor};
  text-transform: none;
  border-radius: 0px 0px 0px 0px;
  margin-left: 10px;
`;

export const boxStyling = styled.div`
  display: "flex";
`;

export const FullScreenView = styled(FullScreen)`
  overflow: auto;
`;

export const StyledDesc = styled.div`
  margin-left: 20px;
  position: relative;
  font-family: ${variables.basefont};
  p {
    margin: 0px;
    padding: 0px;
  }
  a {
    color: #6495ed;
  }
`;
